<template>
  <div class="view">
    <el-tabs class="box1" v-model="activeTab">
      <el-tab-pane label="Staking" name="staking">
        <el-row>
          <el-col :span="20" :xs="24">
            <b class="total-staked">
              Your Total Staked: {{ totalStakingValue.toFixed(2) }} SYP
            </b>
          </el-col>
          <el-col :span="4" style="padding-bottom:5px">
            <el-button
              class="reload btn"
              icon="el-icon-refresh-right"
              @click="reload"
              v-loading="loading"
            >
              REFRESH
            </el-button>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Fee Discount" name="fee-discount">
        <el-row style="height: 42px">
          <el-col :span="20" :md="16">
            <b class="mbb total-staked">
              Your Total Staked: {{ totalStakingValue.toFixed(2) }} SYP
            </b>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <div v-if="activeTab === 'staking'">
      <el-collapse v-model="collapseActiveName" accordion>
        <el-collapse-item name="0" class="section-stk">
          <template slot="title">
            <el-row class="title pc" type="flex" align="middle">
              <el-col :span="6" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking0.lockDayStr }}</span>
              </el-col>
              <el-col :span="6">
                <p>APY</p>
                <p>{{ (staking0.apy * 100).toFixed(2) }} %</p>
              </el-col>
              <el-col :span="6">
                <p>Your Staked</p>
                <p>{{ staking0.stakingValue.toFixed(2) }} SYP</p>
              </el-col>
              <el-col :span="6">
                <p>Pending Reward</p>
                <p>{{ staking0.rewardValue.toFixed(6) }} SYP</p>
              </el-col>
            </el-row>
            <el-row class="title mb" type="flex" align="middle">
              <el-col :span="12" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking0.lockDayStr }}</span>
              </el-col>
              <el-col :span="12">
                <p class="colinner">APY</p>
                <p>{{ (staking0.apy * 100).toFixed(2) }} %</p>
              </el-col>
            </el-row>
          </template>
          <el-row class="btn-box pc" :gutter="15">
            <el-col :span="5" :offset="9">
              <el-button class="btn" @click="actionHarvest" v-loading="acting">
                Harvest
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button
                class="btn"
                @click="unstakeDialog(0)"
                v-loading="acting"
              >
                Unstake
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(0)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>

          <el-row class="btn-box mb" :gutter="15">
            <el-col :span="16">
              <p>Pending Reward</p>
              <p>{{ staking0.rewardValue.toFixed(6) }} SYP</p>
            </el-col>
            <el-col :span="8">
              <el-button class="btn" @click="actionHarvest" v-loading="acting">
                Harvest
              </el-button>
            </el-col>
          </el-row>
          <el-row class="btn-box mb" :gutter="15">
            <el-col :span="8">
              <p>Your Staked</p>
              <p>{{ staking0.stakingValue.toFixed(2) }} SYP</p>
            </el-col>
            <el-col :span="8">
              <el-button
                class="btn"
                @click="unstakeDialog(0)"
                v-loading="acting"
              >
                Unstake
              </el-button>
            </el-col>
            <el-col :span="8">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(0)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item name="30" class="section-stk">
          <template slot="title">
            <el-row class="title pc" type="flex" align="middle">
              <el-col :span="6" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking30.lockDayStr }}</span>
              </el-col>
              <el-col :span="6">
                <p>APY</p>
                <p>{{ (staking30.apy * 100).toFixed(2) }} %</p>
              </el-col>
              <el-col :span="6">
                <p>Your Staked</p>
                <p>{{ staking30.stakingValue.toFixed(2) }} SYP</p>
              </el-col>
              <el-col :span="6">
                <p>Est. Reward</p>
                <p>
                  {{
                    (
                      ((staking30.stakingValue * staking30.apy) / 365) *
                      staking30.lockDay
                    ).toFixed(2)
                  }}
                  SYP
                </p>
              </el-col>
            </el-row>
            <el-row class="title mb" type="flex" align="middle">
              <el-col :span="12" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking30.lockDayStr }}</span>
              </el-col>
              <el-col :span="12">
                <p class="colinner">APY</p>
                <p>{{ (staking30.apy * 100).toFixed(2) }} %</p>
              </el-col>
            </el-row>
          </template>
          <el-table
            :data="staking30.stakingTable"
            class="table-stk"
            v-if="staking30.stakingTable.length > 0"
          >
            <el-table-column label="Staked">
              <template slot-scope="scope"
                >{{ scope.row.value.toFixed(2) }} SYP</template
              >
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <span class="pc">Est. Reward</span>
                <span class="mb">Reward</span>
              </template>
              <template slot-scope="scope">
                <span v-if="scope.row.value > 0">{{
                  (
                    (scope.row.value * staking30.apy * staking30.lockDay) /
                    365
                  ).toFixed(2)
                }}</span>
                <span v-else>{{ scope.row.reward.toFixed(2) }}</span>
                SYP
              </template>
            </el-table-column>
            <el-table-column min-width="100px">
              <template slot="header">
                <span class="pc">Amount after maturity</span>
                <span class="mb">Maturity</span>
              </template>
              <template slot-scope="scope"
                >{{
                  (
                    scope.row.value *
                    (1 + (staking30.apy * staking30.lockDay) / 365)
                  ).toFixed(2)
                }}
                SYP</template
              >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <div class="left-day" v-if="scope.row.leftDay > 0">
                  {{ scope.row.leftDay }} Days
                  <span class="pc">Left</span>
                </div>
                <el-button
                  class="btn"
                  v-else
                  @click="
                    actionWithdraw(scope.row.memberKey, scope.row.lockDay)
                  "
                  v-loading="acting"
                >
                  Withdraw
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row class="btn-box pc" :gutter="15">
            <el-col :span="5" :offset="19">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(30)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>
          <el-row class="btn-box mb" :gutter="15">
            <el-col :span="8">
              <p>Your Staked</p>
              <p>{{ staking30.stakingValue.toFixed(2) }} SYP</p>
            </el-col>
            <el-col :span="8">
              <p>Est. Reward</p>
              <p>
                {{
                  (
                    ((staking30.stakingValue * staking30.apy) / 365) *
                    staking30.lockDay
                  ).toFixed(2)
                }}
                SYP
              </p>
            </el-col>
            <el-col :span="8">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(30)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item name="90" class="section-stk">
          <template slot="title">
            <el-row class="title pc" type="flex" align="middle">
              <el-col :span="6" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking90.lockDayStr }}</span>
              </el-col>
              <el-col :span="6">
                <p>APY</p>
                <p>{{ (staking90.apy * 100).toFixed(2) }} %</p>
              </el-col>
              <el-col :span="6">
                <p>Your Staked</p>
                <p>{{ staking90.stakingValue.toFixed(2) }} SYP</p>
              </el-col>
              <el-col :span="6">
                <p>Est. Reward</p>
                <p>
                  {{
                    (
                      ((staking90.stakingValue * staking90.apy) / 365) *
                      staking90.lockDay
                    ).toFixed(2)
                  }}
                  SYP
                </p>
              </el-col>
            </el-row>
            <el-row class="title mb" type="flex" align="middle">
              <el-col :span="12" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking90.lockDayStr }}</span>
              </el-col>
              <el-col :span="12">
                <p class="colinner">APY</p>
                <p>{{ (staking90.apy * 100).toFixed(2) }} %</p>
              </el-col>
            </el-row>
          </template>
          <el-table
            :data="staking90.stakingTable"
            class="table-stk"
            v-if="staking90.stakingTable.length > 0"
          >
            <el-table-column label="Staked">
              <template slot-scope="scope"
                >{{ scope.row.value.toFixed(2) }} SYP</template
              >
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <span class="pc">Est. Reward</span>
                <span class="mb">Reward</span>
              </template>
              <template slot-scope="scope">
                <span v-if="scope.row.value > 0">{{
                  (
                    (scope.row.value * staking90.apy * staking90.lockDay) /
                    365
                  ).toFixed(2)
                }}</span>
                <span v-else>{{ scope.row.reward.toFixed(2) }}</span>
                SYP
              </template>
            </el-table-column>
            <el-table-column min-width="100px">
              <template slot="header">
                <span class="pc">Amount after maturity</span>
                <span class="mb">Maturity</span>
              </template>
              <template slot-scope="scope"
                >{{
                  (
                    scope.row.value *
                    (1 + (staking90.apy * staking90.lockDay) / 365)
                  ).toFixed(2)
                }}
                SYP</template
              >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <div class="left-day" v-if="scope.row.leftDay > 0">
                  {{ scope.row.leftDay }} Days
                  <span class="pc">Left</span>
                </div>
                <el-button
                  class="btn"
                  v-else
                  @click="
                    actionWithdraw(scope.row.memberKey, scope.row.lockDay)
                  "
                  v-loading="acting"
                >
                  Withdraw
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row class="btn-box pc" :gutter="15">
            <el-col :span="5" :offset="19">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(90)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>
          <el-row class="btn-box mb" :gutter="15">
            <el-col :span="8">
              <p>Your Staked</p>
              <p>{{ staking90.stakingValue.toFixed(2) }} SYP</p>
            </el-col>
            <el-col :span="8">
              <p>Est. Reward</p>
              <p>
                {{
                  (
                    ((staking90.stakingValue * staking90.apy) / 365) *
                    staking90.lockDay
                  ).toFixed(2)
                }}
                SYP
              </p>
            </el-col>
            <el-col :span="8">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(90)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item name="180" class="section-stk">
          <template slot="title">
            <el-row class="title pc" type="flex" align="middle">
              <el-col :span="6" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking180.lockDayStr }}</span>
              </el-col>
              <el-col :span="6">
                <p>APY</p>
                <p>{{ (staking180.apy * 100).toFixed(2) }} %</p>
              </el-col>
              <el-col :span="6">
                <p>Your Staked</p>
                <p>{{ staking180.stakingValue.toFixed(2) }} SYP</p>
              </el-col>
              <el-col :span="6">
                <p>Est. Reward</p>
                <p>
                  {{
                    (
                      ((staking180.stakingValue * staking180.apy) / 365) *
                      staking180.lockDay
                    ).toFixed(2)
                  }}
                  SYP
                </p>
              </el-col>
            </el-row>
            <el-row class="title mb" type="flex" align="middle">
              <el-col :span="12" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking180.lockDayStr }}</span>
              </el-col>
              <el-col :span="12">
                <p class="colinner">APY</p>
                <p>{{ (staking180.apy * 100).toFixed(2) }} %</p>
              </el-col>
            </el-row>
          </template>
          <el-table
            :data="staking180.stakingTable"
            class="table-stk"
            v-if="staking180.stakingTable.length > 0"
          >
            <el-table-column label="Staked">
              <template slot-scope="scope"
                >{{ scope.row.value.toFixed(2) }} SYP</template
              >
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <span class="pc">Est. Reward</span>
                <span class="mb">Reward</span>
              </template>
              <template slot-scope="scope">
                <span v-if="scope.row.value > 0">{{
                  (
                    (scope.row.value * staking180.apy * staking180.lockDay) /
                    365
                  ).toFixed(2)
                }}</span>
                <span v-else>{{ scope.row.reward.toFixed(2) }}</span>
                SYP
              </template>
            </el-table-column>
            <el-table-column min-width="100px">
              <template slot="header">
                <span class="pc">Amount after maturity</span>
                <span class="mb">Maturity</span>
              </template>
              <template slot-scope="scope"
                >{{
                  (
                    scope.row.value *
                    (1 + (staking180.apy * staking180.lockDay) / 365)
                  ).toFixed(2)
                }}
                SYP</template
              >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <div class="left-day" v-if="scope.row.leftDay > 0">
                  {{ scope.row.leftDay }} Days
                  <span class="pc">Left</span>
                </div>
                <el-button
                  class="btn"
                  v-else
                  @click="
                    actionWithdraw(scope.row.memberKey, scope.row.lockDay)
                  "
                  v-loading="acting"
                >
                  Withdraw
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row class="btn-box pc" :gutter="15">
            <el-col :span="5" :offset="19">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(180)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>
          <el-row class="btn-box mb" :gutter="15">
            <el-col :span="8">
              <p>Your Staked</p>
              <p>{{ staking180.stakingValue.toFixed(2) }} SYP</p>
            </el-col>
            <el-col :span="8">
              <p>Est. Reward</p>
              <p>
                {{
                  (
                    ((staking180.stakingValue * staking180.apy) / 365) *
                    staking180.lockDay
                  ).toFixed(2)
                }}
                SYP
              </p>
            </el-col>
            <el-col :span="8">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(180)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item name="365" class="section-stk">
          <template slot="title">
            <el-row class="title pc" type="flex" align="middle">
              <el-col :span="6" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking365.lockDayStr }}</span>
              </el-col>
              <el-col :span="6">
                <p>APY</p>
                <p>{{ (staking365.apy * 100).toFixed(2) }} %</p>
              </el-col>
              <el-col :span="6">
                <p>Your Staked</p>
                <p>{{ staking365.stakingValue.toFixed(2) }} SYP</p>
              </el-col>
              <el-col :span="6">
                <p>Est. Reward</p>
                <p>
                  {{
                    (
                      ((staking365.stakingValue * staking365.apy) / 365) *
                      staking365.lockDay
                    ).toFixed(2)
                  }}
                  SYP
                </p>
              </el-col>
            </el-row>
            <el-row class="title mb" type="flex" align="middle">
              <el-col :span="12" class="type">
                <img class="asset-icon" src="../assets/img/icon_syp.png" alt />
                <span class="text-stk">{{ staking365.lockDayStr }}</span>
              </el-col>
              <el-col :span="12">
                <p class="colinner">APY</p>
                <p>{{ (staking365.apy * 100).toFixed(2) }} %</p>
              </el-col>
            </el-row>
          </template>
          <el-table
            :data="staking365.stakingTable"
            class="table-stk"
            v-if="staking365.stakingTable.length > 0"
          >
            <el-table-column label="Staked">
              <template slot-scope="scope"
                >{{ scope.row.value.toFixed(2) }} SYP</template
              >
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <span class="pc">Est. Reward</span>
                <span class="mb">Reward</span>
              </template>
              <template slot-scope="scope">
                <span v-if="scope.row.value > 0">{{
                  (
                    (scope.row.value * staking365.apy * staking365.lockDay) /
                    365
                  ).toFixed(2)
                }}</span>
                <span v-else>{{ scope.row.reward.toFixed(2) }}</span>
                SYP
              </template>
            </el-table-column>
            <el-table-column min-width="100px">
              <template slot="header">
                <span class="pc">Amount after maturity</span>
                <span class="mb">Maturity</span>
              </template>
              <template slot-scope="scope"
                >{{
                  (
                    scope.row.value *
                    (1 + (staking365.apy * staking365.lockDay) / 365)
                  ).toFixed(2)
                }}
                SYP</template
              >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <div class="left-day" v-if="scope.row.leftDay > 0">
                  {{ scope.row.leftDay }} Days
                  <span class="pc">Left</span>
                </div>
                <el-button
                  class="btn"
                  v-else
                  @click="
                    actionWithdraw(scope.row.memberKey, scope.row.lockDay)
                  "
                  v-loading="acting"
                >
                  Withdraw
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row class="btn-box pc" :gutter="15">
            <el-col :span="5" :offset="19">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(365)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>
          <el-row class="btn-box mb" :gutter="15">
            <el-col :span="8">
              <p>Your Staked</p>
              <p>{{ staking365.stakingValue.toFixed(2) }} SYP</p>
            </el-col>
            <el-col :span="8">
              <p>Est. Reward</p>
              <p>
                {{
                  (
                    ((staking365.stakingValue * staking365.apy) / 365) *
                    staking365.lockDay
                  ).toFixed(2)
                }}
                SYP
              </p>
            </el-col>
            <el-col :span="8">
              <el-button
                class="btn btn-c"
                @click="stakeDialog(365)"
                v-loading="acting"
              >
                Stake
              </el-button>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
      <div class="btn-box3">
        <el-button
          class="go-old btn"
          type="primary"
          @click="jumpRoute('Staking')"
        >
          Swtich to V2
        </el-button>
      </div>
    </div>
    <div v-if="activeTab === 'fee-discount'">
      <div class="content-box">
        <el-table :data="feeDiscountTable" class="fee-discount-table">
          <el-table-column label="SYP Staked" align="center">
            <template slot-scope="scope">
              <span
                :class="{
                  'fee-discount-at':
                    totalStakingValue >= scope.row.min &&
                    totalStakingValue < scope.row.max + 1,
                }"
                >{{ scope.row.staked }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="Burn Fee Discount" align="center">
            <template slot-scope="scope">
              <span
                :class="{
                  'fee-discount-at':
                    totalStakingValue >= scope.row.min &&
                    totalStakingValue < scope.row.max + 1,
                }"
                >{{ (scope.row.burn * 100).toFixed(0) }}%</span
              >
            </template>
          </el-table-column>
          <el-table-column label="Performance Fee Discount" align="center">
            <template slot-scope="scope">
              <span
                :class="{
                  'fee-discount-at':
                    totalStakingValue >= scope.row.min &&
                    totalStakingValue < scope.row.max + 1,
                }"
                >{{ (scope.row.performance * 100).toFixed(0) }}%</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <stake-dialog
      :product="dialogProduct"
      :balance="balance"
      :refreshing="dialogRefreshing"
      :loading="acting"
      @stake="actionStake"
      @refresh="actionRefresh"
      ref="stakeDialog"
    />
    <unstake-dialog
      :product="dialogProduct"
      :balance="staking0.stakingValue"
      :refreshing="dialogRefreshing"
      :loading="acting"
      @unstake="actionUnstake"
      @refresh="actionRefresh"
      ref="unstakeDialog"
    />
  </div>
</template>

<script>
import StakeDialog from "@/components/stakeDialog.vue";
import UnstakeDialog from "@/components/unstakeDialog.vue";
import {
  stakingList,
  getBalanceSyp,
  getStaking,
  getMemberKey,
  getMemberPDA2,
  getMemberData,
  createMemberAcc,
  getMemberVaults,
  createMemberVaults,
  createMember,
  getBalanceSpt,
  calculateSptReward,
  getBalanceRewardSpt,
  stake,
  unstake,
  harvest,
  withdrawal,
  getRegistarData,
  findMemberKey3,
  getMemberDataRaw,
} from "../assets/js/stakingv2/staking";
import {
  getRegistrarData as getRegistrarDataFarming,
  // getMemberData as getMemberDataFarming,
  getMemberVault as getMemberVaultFarming,
  findMember2 as findMemberFarming,
  findRegistrar as findRegistrarFarming,
  getBalance as getBalanceFarming,
  createMember as createMemberFarming,
  directStake as stakeFarming,
  directUnstake as unstakeFarming,
  claimReward as claimFarming,
  getReward,
} from "../assets/js/farming/index";
import { getMemberData as getMemberDataFarming } from "../assets/js/farming/state";
import { feeDiscount } from "../assets/js/feeDiscount";
import { getSignatureStatus } from "../assets/js/getBalance";
import { Connection, PublicKey } from "@solana/web3.js";
import { getTokenName } from "../assets/js/token";
import { getTokenPrice2 } from "../assets/js/oracleList";
import { rpcConfig, rpcUrl } from "../assets/js";
const rateMul = 100000;

export default {
  name: "Staking",
  data() {
    return {
      date: "",
      collapseActiveName: "0",
      balance: 0.0,
      balanceLp: 0.0,
      acting: false,
      loading: false,
      loadingFarming: false,
      activeTab: "staking",
      feeDiscountTable: feeDiscount,

      staking0: {
        lockDayStr: stakingList[0].lockDayStr,
        lockDay: stakingList[0].lockDay,
        apy: stakingList[0].apy,
        stakingValue: 0,
        rewardValue: 0,
        stakingTable: [],
      },
      staking30: {
        lockDayStr: stakingList[1].lockDayStr,
        lockDay: stakingList[1].lockDay,
        apy: stakingList[1].apy,
        stakingValue: 0,
        rewardValue: 0,
        stakingTable: [],
      },
      staking90: {
        lockDayStr: stakingList[2].lockDayStr,
        lockDay: stakingList[2].lockDay,
        apy: stakingList[2].apy,
        stakingValue: 0,
        rewardValue: 0,
        stakingTable: [],
      },
      staking180: {
        lockDayStr: stakingList[3].lockDayStr,
        lockDay: stakingList[3].lockDay,
        apy: stakingList[3].apy,
        stakingValue: 0,
        rewardValue: 0,
        stakingTable: [],
      },
      staking365: {
        lockDayStr: stakingList[4].lockDayStr,
        lockDay: stakingList[4].lockDay,
        apy: stakingList[4].apy,
        stakingValue: 0,
        rewardValue: 0,
        stakingTable: [],
      },

      dialogProduct: { type: 0, reward: 0, lockTime: "", apy: 0 },
      farmingProduct: { name: "" },
      dialogRefreshing: false,

      connection: new Connection(rpcUrl, rpcConfig),
      walletKey: "",

      farmingList: [
        // {
        //   name: 'TCI-USDC',
        //   apy: 0.14,
        //   reward: 0.0,
        //   rewardToken: 'SYP',
        //   TVL: 10200,
        //   raydiumUrl: '',
        //   orcaUrl: '',
        //   stakeToken: '',
        //   stakeAmount: 1.23,
        //   stakeValue: 15.4,
        //   registrarKey: '',
        //   balance: 0.0,
        // },
      ],
    };
  },
  methods: {
    // get data
    reload() {
      if (!this.loading) {
        this.loading = true;
        this.getBalance();
        this.getMemberBalance();
        this.getMemberBalance2(30);
        this.getMemberBalance2(90);
        this.getMemberBalance2(180);
        this.getMemberBalance2(365);
        // getAllRegistrarData(this.connection);
        setTimeout(() => {
          this.loading = false;
        }, 3500);
      }
    },
    async reloadFarming() {
      if (!this.loadingFarming) {
        this.loadingFarming = true;
        await this.getFarming();
        this.loadingFarming = false;
      }
    },
    // open dialog
    stakeDialog(type) {
      console.log("stake", type);
      this.$notify({
        title: "Warning",
        message: "Can't stake in V1.",
        type: "warning",
      });
    },
    unstakeDialog(type) {
      this.dialogProduct = this.getProduct(type);
      this.$refs.unstakeDialog.show = true;
    },
    stakeLpDialog(item) {
      this.farmingProduct = item;
      this.balanceLp = item.balance;
      this.$refs.stakeLpDialog.show = true;
    },
    unstakeLpDialog(item) {
      this.farmingProduct = item;
      this.balanceLp = item.stakeAmount;
      this.$refs.unstakeLpDialog.show = true;
    },
    // staking action
    getProduct(type) {
      let staking = getStaking(type);
      let product = {
        type: staking.lockDay,
        apy: staking.apy,
        lockTime: staking.lockDayStr,
      };
      return product;
    },
    async getBalance() {
      // get syp balance
      const wallet = this.$store.state.walletConnectInfo.wallet;
      let balance = await getBalanceSyp(this.connection, wallet);
      console.log("syp balance", balance);
      if (balance) {
        this.balance = balance.amount;
      }
    },
    async getMember(lockDay) {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      // get staking
      let staking = getStaking(lockDay);
      let memberSeed = staking.memberSeed;
      // get member
      let memberKey;
      if (lockDay == 0) {
        memberKey = await getMemberKey(wallet, memberSeed);
      } else {
        // let temp = await getMemberKey2(wallet, memberSeed, this.connection);
        let time = new Date().getTime().toString();
        memberSeed += time;
        memberKey = await getMemberKey(wallet, memberSeed);
      }
      // get pda
      let { memberPDA, nonce } = await getMemberPDA2(
        staking.registrarKey,
        memberKey
      );
      // get member data
      let memberData = await getMemberData(this.connection, memberKey);
      if (!memberData) {
        // create member account
        let res = await createMemberAcc(
          this.connection,
          wallet,
          memberKey,
          memberSeed
        );
        if (res.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Create member account sign cancelled",
            type: "warning",
          });
          return;
        } else if (res.code == -2) {
          this.$notify({
            title: "Error",
            message: "Wait member account time out",
            type: "error",
          });
          return;
        } else if (res.code == -3) {
          console.log(res);
          this.$notify({
            title: "Error",
            message: "Create member account error",
            type: "error",
          });
          return;
        } else if (res.code == 1) {
          memberData = res.data;
        }
      }
      // get member vaults
      let res = getMemberVaults(memberData);
      let memberVaults;
      if (res.code == 0) {
        // create memeber vaults
        let res2 = await createMemberVaults(
          this.connection,
          wallet,
          staking.registrarKey,
          memberKey
        );
        if (res2.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Create member vaults sign cancelled",
            type: "warning",
          });
          return;
        } else if (res2.code == -3) {
          console.log("create member vaults error", res2.data);
          this.$notify({
            title: "Error",
            message: "Create member vaults error",
            type: "error",
          });
          return;
        } else if (res2.code == 1) {
          memberVaults = res2.data;
        }
        // create member
        let res3 = await createMember(
          this.connection,
          wallet,
          memberKey,
          memberVaults,
          staking.registrarKey
        );
        if (res3.code == -2) {
          this.$notify({
            title: "Error",
            message: "Wait member account time out",
            type: "error",
          });
          return;
        } else if (res3.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Create member sign cancelled",
            type: "warning",
          });
          return;
        } else if (res3.code == -3) {
          console.log("create member error", res3.data);
          this.$notify({
            title: "Error",
            message: "Create member error",
            type: "error",
          });
          return;
        } else if (res3.code == 1) {
          memberData = res3.data;
          let res4 = getMemberVaults(memberData);
          memberVaults = res4.data;
        }
      } else if (res.code == 1) {
        memberVaults = res.data;
      }
      return { memberKey, memberVaults, memberPDAKey: memberPDA.toBase58() };
    },
    async getMemberBalance() {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      // const wallet = { publicKey: new PublicKey('DtmerpXbLKEMFsec4SsfB9Fi7WbsZ51dD5aJni6ZurvM') };
      // get staking
      let staking = getStaking(0);
      // get member key
      const memberKey = await getMemberKey(wallet, staking.memberSeed);
      // get member data
      let memberData = await getMemberData(this.connection, memberKey);
      if (memberData) {
        // console.log("member key 0", memberKey, memberData);
        // get spt balance
        let balance = await getBalanceSpt(this.connection, memberKey);
        if (balance) {
          this.$set(this.staking0, "stakingValue", balance.amount);
          let sptReward = calculateSptReward(memberData, balance, staking.apy);
          let rewardBalance = await getBalanceRewardSpt(
            this.connection,
            memberKey
          );
          this.$set(
            this.staking0,
            "rewardValue",
            rewardBalance.amount + sptReward
          );
        }
      }
    },
    async getMemberBalance2(lockDay) {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      // const wallet = { publicKey: new PublicKey('DtmerpXbLKEMFsec4SsfB9Fi7WbsZ51dD5aJni6ZurvM') };
      // get staking
      let staking = getStaking(lockDay);
      // get registrar data
      let registrarData = await getRegistarData(
        this.connection,
        staking.registrarKey
      );
      // get member key
      let list = await findMemberKey3(
        this.connection,
        wallet,
        staking.registrarKey
      );
      // console.log(lockDay, list.length);
      if (list.length > 0) {
        let stakingTable = [];
        let totalValue = 0;
        for (let i = 0; i < list.length; i++) {
          let member = list[i];
          let memberKey = member.pubkey.toBase58();
          // get member data
          let memberData = getMemberDataRaw(member.account);
          if (memberData) {
            // console.log("member key", lockDay, memberKey, memberData);
            if (memberData.lastStakeTs > 0) {
              // calculate left day
              let nowTime = new Date().getTime();
              let leftTime =
                registrarData.withdrawalTimeLock * 1000 -
                (nowTime - memberData.lastStakeTs * 1000);
              if (leftTime < 0) {
                leftTime = 0;
              }
              let leftDay = Math.ceil(leftTime / (1000 * 3600 * 24));
              // get spt balance
              let balance = await getBalanceSpt(this.connection, memberKey);
              if (balance) {
                let { amount } = await getBalanceRewardSpt(
                  this.connection,
                  memberKey
                );
                if (balance.amount > 0 || amount > 0) {
                  // assembly member table data
                  stakingTable.push({
                    value: balance.amount,
                    leftDay,
                    memberKey,
                    reward: amount,
                    lockDay,
                  });
                  totalValue += balance.amount;
                }
              }
            }
          }
        }
        if (lockDay == 30) {
          this.$set(this.staking30, "stakingTable", stakingTable);
          this.$set(this.staking30, "stakingValue", totalValue);
        } else if (lockDay == 90) {
          this.$set(this.staking90, "stakingTable", stakingTable);
          this.$set(this.staking90, "stakingValue", totalValue);
        } else if (lockDay == 180) {
          this.$set(this.staking180, "stakingTable", stakingTable);
          this.$set(this.staking180, "stakingValue", totalValue);
        } else if (lockDay == 365) {
          this.$set(this.staking365, "stakingTable", stakingTable);
          this.$set(this.staking365, "stakingValue", totalValue);
        }
      }
    },
    async goStake(amount, lockDay) {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      let member = await this.getMember(lockDay);
      if (!member) {
        console.log("member is null");
        return;
      }
      const { memberKey, memberVaults, memberPDAKey } = member;
      if (
        memberKey == undefined ||
        memberVaults == undefined ||
        memberPDAKey == undefined
      ) {
        console.log("member is null");
        return;
      }
      let res = await stake(this.connection, wallet, amount, memberKey);
      if (res.code == -1) {
        console.log("stake error", res.data);
        this.$notify({
          title: "Warning",
          message: "Stake sign cancelled",
          type: "warning",
        });
      } else if (res.code == 0) {
        this.$notify({
          title: "Failure",
          message: "Transaction failed",
          type: "error",
        });
        console.log("stake error", res.data);
      } else if (res.code == -3) {
        this.$notify({
          title: "Failure",
          message: "Transaction failed",
          type: "error",
        });
        console.log("stake error", res.data);
      } else if (res.code == 1) {
        let msg = "stake transaction confirmed";
        this.notifyWithSignature(msg, res.data);
        console.log("stake signature", res.data);
      } else {
        this.$notify({
          title: "Failure",
          message: "Transaction failed",
          type: "error",
        });
      }
    },
    async goUnstake(amount, lockDay) {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      const { memberKey, memberVaults, memberPDAKey } = await this.getMember(
        lockDay
      );
      if (
        memberKey == undefined ||
        memberVaults == undefined ||
        memberPDAKey == undefined
      ) {
        return;
      }
      let res = await unstake(this.connection, wallet, amount, memberKey);
      if (res.code == 1) {
        let msg = "unstake transaction confirmed";
        this.notifyWithSignature(msg, res.data);
        console.log("unstake signature", res.data);
      } else if (res.code == -1) {
        this.$notify({
          title: "Warning",
          message: "Unstake sign cancelled",
          type: "warning",
        });
        return;
      } else if (res.code == -3) {
        console.error("unstake error", res.data);
        this.$notify({
          title: "Failure",
          message: "Transaction failed",
          type: "error",
        });
        return;
      }
    },
    async goWithdrawal(memberKey, lockDay) {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      let memberData = await getMemberData(this.connection, memberKey);
      if (memberData == null || memberData.lastStakeTs == 0) {
        return;
      }
      let { amount } = await getBalanceSpt(this.connection, memberKey);
      if (amount > 0) {
        let res = await unstake(this.connection, wallet, amount, memberKey);
        if (res.code == 1) {
          let msg = "withdrawal transaction confirmed";
          this.notifyWithSignature(msg, res.data);
          console.log("withdrawal signature", res.data);
        } else if (res.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Withdrawal sign cancelled",
            type: "warning",
          });
          return;
        } else if (res.code == -3) {
          console.error("withdrawal error", res.data);
          this.$notify({
            title: "Failure",
            message: "Transaction failed",
            type: "error",
          });
          return;
        }
      }
      let { amount: amount1 } = await getBalanceRewardSpt(
        this.connection,
        memberKey
      );
      if (amount1 > 0) {
        const staking = getStaking(lockDay);
        let res = await harvest(
          this.connection,
          wallet,
          memberKey,
          staking.vendorKey
        );
        if (res.code == 1) {
          let msg = "harvest transaction confirmed";
          this.notifyWithSignature(msg, res.data);
          console.log("harvest signature", res.data);
        } else if (res.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Harvest sign cancelled",
            type: "warning",
          });
        } else if (res.code == -3) {
          console.error("harvest error", res.data);
          this.$notify({
            title: "Failure",
            message: "Transaction failed",
            type: "error",
          });
        }
      }
    },
    async goWithdrawal2(memberKey, lockDay) {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      let memberData = await getMemberData(this.connection, memberKey);
      if (memberData == null || memberData.lastStakeTs == 0) {
        return;
      }
      let { amount } = await getBalanceSpt(this.connection, memberKey);
      if (amount > 0) {
        let staking = getStaking(lockDay);
        let res = await withdrawal(
          this.connection,
          wallet,
          amount,
          memberKey,
          staking.vendorKey
        );
        if (res.code == 1) {
          let msg = "withdrawal transaction confirmed";
          this.notifyWithSignature(msg, res.data);
          console.log("withdrawal signature", res.data);
        } else if (res.code == -1) {
          this.$notify({
            title: "Warning",
            message: "Withdrawal sign cancelled",
            type: "warning",
          });
          return;
        } else if (res.code == -3) {
          console.error("withdrawal error", res.data);
          this.$notify({
            title: "Failure",
            message: "Transaction failed",
            type: "error",
          });
          return;
        }
      }
    },
    async goHarvest() {
      const wallet = this.$store.state.walletConnectInfo.wallet;
      const { memberKey, memberVaults, memberPDAKey } = await this.getMember(0);
      const staking = getStaking(0);
      let res = await harvest(
        this.connection,
        wallet,
        memberKey,
        staking.vendorKey
      );
      if (res.code == 1) {
        let msg = "harvest transaction confirmed";
        this.notifyWithSignature(msg, res.data);
        console.log("harvest signature", res.data);
      } else if (res.code == -1) {
        this.$notify({
          title: "Warning",
          message: "Unstake sign cancelled",
          type: "warning",
        });
      } else if (res.code == -3) {
        console.error("harvest error", res.data);
        this.$notify({
          title: "Failure",
          message: "Transaction failed",
          type: "error",
        });
      }
    },
    async actionStake(data) {
      // let { type, amount } = data;
      if (!this.acting) {
        this.acting = true;
        this.$notify({
          title: "Warning",
          message: "Stake is disabled.",
          type: "warning",
        });
        // await this.goStake(amount, type);
        this.acting = false;
      }
    },
    async actionUnstake(data) {
      let { type, amount } = data;
      if (!this.acting) {
        this.acting = true;
        try {
          await this.goUnstake(amount, type);
        } catch (err) {
          console.log("unstake error", err);
          this.$notify({
            title: "Failure",
            message: "Transaction failed",
            type: "error",
          });
        }
        this.acting = false;
      }
    },
    async actionHarvest() {
      if (!(this.staking0.rewardValue > 0)) {
        this.$notify({ title: "Error", message: "No reward.", type: "error" });
        return;
      }
      if (!this.acting) {
        this.acting = true;
        try {
          await this.goHarvest();
        } catch (err) {
          console.log("harvest error", err);
          this.$notify({
            title: "Failure",
            message: "Transaction failed",
            type: "error",
          });
        }
        this.acting = false;
      }
    },
    async actionWithdraw(memberKey, lockDay) {
      if (!this.acting) {
        this.acting = true;
        try {
          await this.goWithdrawal2(memberKey, lockDay);
        } catch (err) {
          console.log("withdrawal error", err);
          this.$notify({
            title: "Failure",
            message: "Transaction failed",
            type: "error",
          });
        }
        this.acting = false;
      }
    },
    // farming action
    async getFarming() {
      let wallet = this.$store.state.walletConnectInfo.wallet;
      let registrarList = await findRegistrarFarming(this.connection);
      let data = [];
      for (let i = 0; i < registrarList.length; i++) {
        // get registrar
        let registrarKey = registrarList[i].toBase58();
        let registrarData = await getRegistrarDataFarming(
          this.connection,
          registrarKey
        );
        let timelock = 0;
        if (registrarData) {
          let mintName = getTokenName(registrarData.mint);
          let name = mintName;
          let fees = 0;
          let tvl = 0;
          {
            let res = await this.$axios.post("/getFarming", {
              publicKey: registrarKey,
            });
            if (res.data.code == 1) {
              name = res.data.data.name;
              fees = res.data.data.fees;
              tvl = res.data.data.tvl;
            }
          }
          let rewardMintName = getTokenName(registrarData.rewardMint);
          timelock = registrarData.withdrawalTimeLock * 1000;
          // get user token amount
          let balance = 0.0;
          {
            let res = await getBalanceFarming(
              this.connection,
              wallet,
              registrarKey
            );
            if (res.code == 1) {
              balance = res.data;
            } else {
              console.error("get farming token balance error", res);
            }
          }
          // get mint price
          let price = 0.0;
          if (tvl) {
            price = tvl;
          } else {
            let res = await getTokenPrice2(this.connection, mintName);
            if (res.code == 1) {
              price = res.data;
            } else {
              console.error("get mint price fail", res);
            }
          }
          let rewardPrice = 0.0;
          {
            let res = await getTokenPrice2(this.connection, rewardMintName);
            if (res.code == 1) {
              rewardPrice = res.data;
            } else {
              console.error("get reward mint price fail", res);
            }
          }
          // get member
          let stakeAmount = 0;
          let stakeAmountWithoutDecimals = 0.0;
          let stakeValue = 0;
          let cursor = 0;
          let lastStakeTs = new Date().getTime();
          let memberAcc = await findMemberFarming(
            this.connection,
            wallet,
            registrarKey
          );
          if (memberAcc) {
            let memberData = await getMemberDataFarming(
              this.connection,
              memberAcc.toBase58()
            );
            if (memberData) {
              lastStakeTs = memberData.lastStakeTs * 1000;
              cursor = memberData.rewardCursor;
            }
            let balance = await getMemberVaultFarming(
              this.connection,
              memberAcc.toBase58()
            );
            stakeAmount = balance.amount;
            stakeAmountWithoutDecimals = balance.amount * 10 ** balance.decimal;
            stakeValue = stakeAmount * price;
          }
          // get vendor
          let rewardAmount = 0;
          {
            let res = await getReward(this.connection, wallet, registrarKey);
            if (res.code == 1) {
              rewardAmount = res.data;
            } else {
              console.log(res);
            }
          }
          // calculate time
          let nowTime = new Date().getTime();
          let unlockTime = lastStakeTs + timelock;
          let leftDay = 0;
          if (unlockTime > nowTime) {
            leftDay = Math.ceil((unlockTime - nowTime) / (24 * 3600 * 1000));
          }
          let rewardValue = rewardAmount * rewardPrice;
          let apy = rewardValue / stakeValue;
          if (stakeValue == 0) apy = 0;
          let temp = {
            name: name,
            apy: apy,
            reward: rewardAmount,
            rewardToken: rewardMintName,
            TVL: tvl,
            fees: fees,
            raydiumUrl: "https://dex.raydium.io/",
            orcaUrl: "https://www.orca.so/",
            stakeToken: mintName,
            stakeAmount: stakeAmount,
            stakeValue: stakeValue,
            registrarKey: registrarKey,
            balance,
            leftDay,
          };
          data.push(temp);
          this.farmingList = data;
        }
      }
      this.farmingList = data;
    },
    async actionStakeLp(item) {
      if (!this.acting) {
        this.acting = true;
        try {
          let wallet = this.$store.state.walletConnectInfo.wallet;
          let registrarKey = item.product.registrarKey;
          let memberAcc = await findMemberFarming(
            this.connection,
            wallet,
            registrarKey
          );
          if (!memberAcc) {
            let res = await createMemberFarming(
              this.connection,
              wallet,
              registrarKey
            );
            if (res.code == 1) {
              memberAcc = new PublicKey(res.data);
            } else {
              console.log("create member farming error", res);
              this.$notify({
                title: "Failure",
                message: "Transaction failed",
                type: "error",
              });
              return;
            }
          }
          let res = await stakeFarming(
            this.connection,
            wallet,
            memberAcc.toBase58(),
            item.amount
          );
          if (res.code == 1) {
            let msg = "Stake transaction confirmed";
            this.notifyWithSignature(msg, res.signatrue);
            console.log("stake signature", res.signatrue);
          } else {
            console.error("stake farming error", res);
            this.$notify({
              title: "Failure",
              message: "Transaction failed",
              type: "error",
            });
          }
        } catch (e) {
          console.error("farming stake error", e);
          this.$notify({
            title: "Failure",
            message: "Stake LP error.",
            type: "error",
          });
        }
        this.acting = false;
      }
    },
    async actionUnstakeLp(item) {
      if (!this.acting) {
        this.acting = true;
        try {
          let wallet = this.$store.state.walletConnectInfo.wallet;
          let registrarKey = item.product.registrarKey;
          let memberAcc = await findMemberFarming(
            this.connection,
            wallet,
            registrarKey
          );
          if (!memberAcc) {
            this.$notify({
              title: "Warning",
              message: "Null member",
              type: "warning",
            });
            return;
          }
          let res = await unstakeFarming(
            this.connection,
            wallet,
            memberAcc.toBase58(),
            item.amount
          );
          if (res.code == 1) {
            let msg = "Unstake transaction confirmed";
            this.notifyWithSignature(msg, res.signatrue);
            console.log("unstake signature", res.signatrue);
          } else {
            console.error("unstake farming error", res);
            this.$notify({
              title: "Failure",
              message: "Transaction failed",
              type: "error",
            });
          }
        } catch (e) {
          console.error("farming unstake error", e);
          this.$notify({
            title: "Failure",
            message: "Unstake LP error.",
            type: "error",
          });
        }
        this.acting = false;
      }
    },
    async farmingHarvest(item) {
      if (!this.acting) {
        this.acting = true;
        try {
          if (item.reward == 0) {
            this.$notify({
              title: "Warning",
              message: "No reward.",
              type: "warning",
            });
            this.acting = false;
            return;
          }
          let wallet = this.$store.state.walletConnectInfo.wallet;
          let registrarKey = item.registrarKey;
          let memberAcc = await findMemberFarming(
            this.connection,
            wallet,
            registrarKey
          );
          if (!memberAcc) {
            this.$notify({
              title: "Warning",
              message: "Null member",
              type: "warning",
            });
            this.acting = false;
            return;
          }
          let res = await claimFarming(
            this.connection,
            wallet,
            memberAcc.toBase58()
          );
          if (res.code == 1) {
            let msg = "Harvest transaction confirmed";
            this.notifyWithSignature(msg, res.signatrue);
            console.log("harvest signature", res.signatrue);
          } else {
            console.error("harvest farming error", res);
            this.$notify({
              title: "Failure",
              message: "Transaction failed",
              type: "error",
            });
          }
        } catch (e) {
          console.error("farming harvest error", e);
          this.$notify({
            title: "Failure",
            message: "Harvest error.",
            type: "error",
          });
        }
        this.acting = false;
      }
    },
    // refresh
    async actionRefresh() {
      this.dialogRefreshing = true;
      await this.getBalance();
      await this.getMemberBalance(0);
      this.dialogRefreshing = false;
    },
    async actionRefresh2(lockDay) {
      await this.getBalance();
      await this.getMemberBalance(lockDay);
    },
    async actionRefreshFarming() {},
    // comm
    init() {
      if (this.$store.state.walletConnectInfo) {
        // this.connection = this.$store.state.walletConnectInfo.connection;
        this.walletKey =
          this.$store.state.walletConnectInfo.publicKey.toBase58();
        return { code: 1 };
      } else {
        console.error("hasn`t connect to wallet");
        return { code: 0 };
        // this.$router.push('/');
      }
    },
    async notifyWithSignature(msg, signature) {
      let temp = await getSignatureStatus(this.connection, signature);
      const h = this.$createElement;
      this.$notify({
        title: "Success",
        dangerouslyUseHTMLString: true,
        message: h("div", {}, [
          h("p", {}, msg),
          h("p", {}, [
            h(
              "a",
              {
                attrs: {
                  href: "https://explorer.solana.com/tx/" + signature,
                  target: "_blank",
                },
              },
              "view on explore"
            ),
          ]),
        ]),
        type: "success",
      });
    },
    jumpRoute(name) {
      this.$router.push({ name });
    },
  },
  mounted() {
    this.date = new Date().toDateString();
    if (this.$route.params.tab) {
      this.activeTab = this.$route.params.tab;
    }
    if (this.$route.params.lockday) {
      this.collapseActiveName = this.$route.params.lockday;
    }
    if (this.$store.state.walletConnectInfo) {
      // this.connection = this.$store.state.walletConnectInfo.connection;
      this.walletKey = this.$store.state.walletConnectInfo.publicKey.toBase58();
      this.reload();
    } else {
      console.error("hasn`t connect to wallet");
      // this.$router.push('/');
    }
    // console.log("****this.walletKey", this.$store.state.walletConnectInfo.publicKey.toBase58())
  },
  computed: {
    totalStakingValue() {
      let total =
        this.staking0.stakingValue +
        this.staking30.stakingValue +
        this.staking90.stakingValue +
        this.staking180.stakingValue +
        this.staking365.stakingValue;
      return total;
    },
  },
  components: {
    StakeDialog,
    UnstakeDialog,
  },
};
</script>

<style lang="less" scoped>
.view {
  height: 100%;
  overflow-y: auto;
  padding: 0 10%;
  @media (max-width: 980px) {
    padding: 0;
  }
}

.box1 {
  padding: 25px;
  background-color: #1b222f;
  border-radius: 15px;
}

.content-box {
  margin-top: 15px;
  padding: 25px;
  background-color: #131315;
  border-radius: 15px;
}

.reload {
  width: 115px;
  padding-left: 15px;
}

.total-staked {
  font-size: 20px;
}

.btn-box3 {
  height: 60px;
  position: relative;

  .go-old {
    position: absolute;
    right: 0;
    width: 130px;
    padding-left: 15px;
  }
}

.section-stk {
  margin: 15px auto;
  padding: 25px;
  box-sizing: border-box;
  background-color: #131315;
  border-radius: 15px;

  .title {
    width: 100%;
    font-size: 20px;
    color: white;
    line-height: 1.5;
    padding-bottom: 15px;

    .type {
      display: flex;
      align-items: center;

      .farmText {
        font-weight: normal;
      }

      .asset-icon {
        height: 30px;
        width: 30px;
        object-fit: contain;
      }
    }

    .el-col {
      > p:nth-child(1) {
        font-size: 16px;
      }
    }
  }

  .btn-box {
    padding: 25px 25px 0;
    border-top: solid #1b1b1b 2px;
  }

  .FarmingBtn-box {
    padding-top: 25px;
    font-size: 1vw;
    border-top: solid #1b1b1b 2px;

    .title {
      color: #fff;
    }

    .farminnerT {
      font-size: 16px;
      color: white;
      margin-bottom: 5px;
    }

    .farminner {
      font-size: 16px;
      color: #665e6d;

      > a {
        color: #665e6d;
        text-decoration-line: none;

        :visited {
          color: #665e6d;
        }
      }

      display: inline !important;
    }

    .farmicon {
      font-size: 16px;
      color: #646473;
      margin-left: 5px;
    }

    .farminnerDiv {
      border: 1px solid;
      border-radius: 5px;
      padding: 10px;
      color: #fff;
      height: 110px;
      position: relative;

      .FDT {
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 15px;
      }

      .FDP0 {
        font-size: 24px;
        margin-bottom: 20px;
      }

      .FDP {
        font-size: 18px;
        line-height: 18px;
      }

      .farmbtn {
        width: 80px;
        height: 42px;
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 0px;
        font-size: 16px;
      }

      .farmbtnFR1 {
        font-size: 26px;
        width: 40px;
        right: 100px;
      }

      .farmbtnS {
        width: 90%;
        right: 5%;
      }
    }
  }

  .btn {
    font-size: 16px;
    padding: 12px 0px;
    width: 100%;
  }

  @media (max-width: 1300px) and (min-width: 981px) {
    .title {
      font-size: 16px;
    }

    .btn {
      font-size: 14px;
      letter-spacing: 0 !important;
    }

    .FDP0 {
      font-size: 18px !important;
    }

    .farmbtn {
      width: 75px !important;
      height: 30px !important;
    }

    .farmbtnFR1 {
      width: 40px !important;
      right: 95px !important;
    }
  }

  .table-stk {
    border-top: solid #1b1b1b 2px;
  }

  .el-table {
    font-size: 20px;
    color: white;
  }

  .left-day {
    font-size: 19px;
    padding: 12px 0;
    width: 100%;
    background-color: #595959;
    color: white;
    text-align: center;
    cursor: not-allowed;
  }

  .left-day2 {
    width: 110px;
    height: 42px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 0px;
    font-size: 16px;
    background-color: #595959;
    color: white;
    text-align: center;
    cursor: not-allowed;
  }
}

.farming-empty {
  height: 20vh;
}

.farmingSS {
  padding-bottom: 0px;
}

.fee-discount-table {
  &.el-table {
    color: white;
    // width: 90%;
    margin: 0 auto;
    font-size: 20px;

    .fee-discount-at {
      color: #293fa7;
      font-size: 28px;
      font-weight: 900;
      line-height: 50px;
    }

    .cell {
      // line-height: 25px !important;
      word-break: normal;
    }
  }
}

@media (max-width: 980px) {
  .section-stk {
    width: 100%;
    padding: 15px;
    padding-bottom: 0;

    .title {
      font-size: 16px;
      color: white;
      font-weight: normal;

      .type {
        .asset-icon {
          height: 20px;
          width: 20px;
          margin-right: 8px;
        }
      }

      .colinner {
        font-size: 14px;
      }
    }

    .btn-box {
      width: 100%;
      padding: 10px 0px 0 5px;
      overflow: hidden;
      border-top: 2px solid #131226;
      font-size: 16px;
      color: #ffffff;

      span {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
      }
    }

    .btn {
      width: 96px;
      height: 25px;
      font-size: 14px;
      padding: 0;
      width: 100%;
      margin: 17px 0;
      letter-spacing: 0px;
    }

    .table-stk {
      .el-table__header {
        .cell {
          font-size: 16px;
          word-break: normal;
        }
      }

      .el-table__body {
        .cell {
          font-size: 16px;
          word-break: normal;

          .left-day {
            font-size: 16px;
            padding: 5px 0;
          }
        }
      }
    }
  }

  .fee-discount-table {
    &.el-table {
      color: white;
      width: 100%;
      background: #1a1732 !important;

      .fee-discount-at {
        color: #293fa7;
        font-size: 16px;
        font-weight: normal;
      }

      .cell {
        font-size: 12px;
        font-weight: normal;
        padding: 0;
      }

      td {
        padding: 5px 0;
      }

      th {
        padding-bottom: 0;
      }

      th > .cell {
        padding: 0 10px;
        height: 48px;
        font-size: 14px;
        border-bottom: 1px solid #969696 !important;
      }
    }
  }

  .FDT {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
  }

  .FDTmb {
    font-size: 14px;
    margin-top: 17px;
  }

  .FDP {
    font-size: 16px;
    line-height: 16px;
  }

  .farmbtn {
    width: 35px !important;
    background-color: transparent;
    margin-left: 28px !important;
    font-weight: 500 !important;
    border-image: linear-gradient(to right, #d922fa, #1be9a8) 1;
  }

  .farminner {
    display: initial;
    font-size: 14px;
    color: #646473;
  }

  .farmicon {
    margin: 0 15px 0 5px;
  }

  .mbb {
    font-weight: normal;
  }
}
</style>
